html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.audio-list-loading-container {
  position: absolute;
  left: calc(50% - 50px);
}

.login-color-white > label {
  color: white !important;
}

.login-color-white > div > input {
  background-color: white;
}

.home-image-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tela-principal-container {
  width: 100%;
  background: center center/cover no-repeat;
  height: calc(100vh - 54px);
}

.menu-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.menu-item-icon {
  width: 100%;
  height: 60px;
  object-fit: contain;
  top: 50px;
  position: absolute;
}

.menu-item-text {
  position: absolute;
  top: 135px;
  left: 0;
  right: 0;
  padding: 0px 5px;
  color: white;
  text-align: center;
}

#standard-basic {
  padding: 6px 0 7px 5px;
}

.tela-login-container {
  width: 100%;
  height: 100vh;
  background: center center/cover no-repeat;
  background-color: rgba(247, 245, 245, 0.69);
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 25% 0px;
}

.tela-login-loading {
  padding: 10px 50px;
  border-radius: 10px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
}

.registro-wrapper {
  height: 100vh;
  background-color: rgba(0,0,0,0.05);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.registro-container {
  background-color: white;
  width: 500px;
  border-radius: 5px;
  padding: 15px 30px;
}

.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
  animation: fadein 0.6s;
}

.tela-login-loading > .loader-container {
  height: auto;
}

.tela-login-loading.bucket-error {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader {
  border: 2px solid rgba(120, 120, 120, 0.5); /* Light grey */
  border-top: 2px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  right: 15px;
}

.loader-big {
  width: 50px;
  height: 50px;
  border-width: 6px;
  border-top: 6px solid white;
}

.loader-purple {
  border-top: 6px solid #861414;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gradient-support {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.mosaic-support {
  width: 100%;
  height: 100%;
  position: absolute;
}

.gradient-support-menu-find {
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.mosaic-text {
  font-size: 18px;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  padding: 0px 5px;
  color: white;
  text-align: center;
}

.blast-footer {
  color: white;
  background-color: #861414;
  font-size: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  height: 5rem;
  z-index: 10;
}

.blast-footer-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 800px;
}

.pwa-safari-indicator {
  position: fixed;
  bottom: 20px;
  width: 80%;
  background-color: white;
  border-radius: 10px;
  z-index: 100;
  left: 0;
  right: 0;
  margin: auto;
  justify-content: center;
  padding: 15px;
  font-size: 12px;
  text-align: center;
  border: 1px solid lightgray;
}

.pwa-safari-indicator:before {
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - 15px);
  width: 0;
  border-top: 15px solid white;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.pwa-safari-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 7px;
}

.blast-topbar {
  background-color: #861414;
  z-index: 100;
  height: 54px;
}

.blast-topbar-img {
  max-height: 38px;
  height: auto;
  width: auto;
}

.navbar.blast-footer {
  padding: 0 !important;
}

.navbar > div > a {
  color: white;
}

.rating-container {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rate-container {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-player-rating > .rating-container > div {
  color: inherit;
}

.video-container-mosaic > video {
  object-fit: cover;
}

.rating-container-existing {
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rating-number-votes,
.rating-number-votes-zero {
  font-family: "PT Sans Narrow";
}

.rating-number-votes {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
}

.rating-number-votes-zero {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
}

.menu-footer-container {
  height: 75px;
  width: 100%;
  position: absolute;
  background-color: #933939;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 15px;
  z-index: 20;
}

.content-site-rating {
  color: lightgray;
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.content-site-rating > .rating-container {
  padding: 0px 10px;
}

.menufind-star-container {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 10;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.menu-footer-icon {
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid white;
  background-color: lightslategray;
  margin-right: 15px;
}

.conteudo-icon {
  height: 70px;
  width: 279px;
  display: flex;
  cursor: pointer;
  position: relative;
}

.editar-perfil-icon {
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid white;
  background-color: lightslategray;
  float: left;
  width: 55px;
  height: 55px;
}

.editar-perfil-icon-home {
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid white;
  background-color: lightslategray;
  /* margin-right: 15px; */
  float: left;
  width: 100px;
}

.espaco-checkbox-principal {
  margin-bottom: -4px;
}

.button-carrega-modal-avatar {
  position: absolute;
  top: 15px;
  height: 77px;
  opacity: 0;
  width: 71px;
}

.button-carrega-modal-avatar-perfil {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}
.bs-camera {
  color: rgb(233, 196, 106);
  position: absolute;
  left: 0px;
  bottom: 10px;
}

@media (min-width: 500px) {
  .modal-dialog {
    margin-top: 60px !important;
  }
}

.input-file-img-user {
  margin: 10px;
}

.input-form-file {
  margin: 15px 5px 0px 5px;
}

.btn-footer {
  margin: 0px auto 15px auto;
  width: 90%;
}

.conteudo-img {
  height: 200px;
}

.clear-b {
  clear: both;
}

.reactEasyCrop_Container {
  top: 56px !important;
  height: 180px !important;
}

.btn-form-save-file {
  width: 100px;
  margin: auto;
}

.titulo-sel {
  text-align: center;
  margin-top: 10px;
}

.espaco-checkbox {
  margin-bottom: -5px;
}

.foto-capa-perfil {
  border-radius: 50px;
}

.menu-footer-name {
  color: white;
  display: flex;
  flex-direction: column;
}

.menu-footer-leave {
  cursor: pointer;
  transition: transform 0.1s ease;
}
.menu-footer-leave-div {
  display: flex;
  right: 0;
  position: absolute;
  width: 95px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.menu-footer-leave-div:hover{
  cursor: pointer;
}

.menu-footer-leave-div:hover > .menu-footer-leave{
  transform: scale(1.1);
  transition: transform 0.1s ease;
}

.menu-expand {
  width: 100%;
  top: 0;
  position: fixed;
  background-color: #861414;
  height: calc(100%);
  transition: 0.3s;
  overflow-y: hidden;
  overflow-x: hidden;
  left: 100%;
  z-index: 1040;
}

.menu-expand-open {
  left: 0%;
  width: 100%;
  z-index: 1040;
}

@media (min-width: 800px) {
  .menu-expand-open {
    left: 60%;
    width: 40%;
  }
}

.login-error-container {
  margin: 10px 0px 0px 0px;
  text-align: left;
}

.login-forgot-container {
  margin: 30px 0px 0px 0px;
  text-align: left;
}

a:hover {
  text-decoration: none !important;
}

.menufind-container {
  padding: 15px;
  width: 100%;
}

.menufind-item {
  background-color: white;
  animation: fadeInMenu ease 0.3s;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
}

@keyframes fadeInMenu {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.list-search-button {
  cursor: pointer;
  transition: width 0.3s ease, background-color 0.5s ease;
  padding: 5px;
  border-radius: 50px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
}

.list-search-button > .menu-search-input {
  width: calc(100% - 130px);
}

.list-search-button::placeholder {
  color: white !important;
}

.chatInput::placeholder {
  color: gray;
}

.menu-item-container {
  width: 100%;
}

.menu-item-container:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0px;
  margin-left: 35px;
  margin-top: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.menu-item-container > a {
  color: white;
}

.menu-item-container > a:visited {
  color: white;
  text-decoration: none;
}

.menu-item-container > a:hover {
  color: white;
  text-decoration: none;
}

.menu-text {
  font-size: 16px;
  font-weight: bold;
}

.menu-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-close {
  cursor: pointer;
  height: 100%;
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.menu-close-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #933939;
}

.menu-search-button {
  right: 15px;
  position: absolute;
  cursor: pointer;
  transition: width 0.3s ease, background-color 0.5s ease;
  padding: 5px;
  width: 35px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 50px;
  height: 35px;
}

.menu-search-clickable-expand {
  width: 35px;
  height: 30px;
  position: absolute;
  right: 0px;
}

.menu-search-input {
  position: absolute;
  background-color: transparent;
  border: 0;
  width: 240px;
  color: black;
  margin-left: 5px;
}

.pages-container {
  padding: 15px 0;
  color: white;
  display: flex;
  justify-content: center;
}

.menufind-item-image {
  height: 100px;
  margin-right: 10px;
  border-radius: 5px;
  min-height: 100px;
  min-width: 100px;
}

.menufind-item-description5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 20px;
}

.menufind-item-image5 {
  width: 100%;
  object-fit: cover;
  height: 115px;
  border-radius: 5px;
  min-height: 115px;
}

.menufind-item-image3 {
  width: 125px;
  height: 115px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.page-item {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background-color: #933939;
  border-radius: 50px;
}

.menufind-item-content {
  color: black;
  position: relative;
}

.menufind-item-content-default {
  color: black;
  display: flex;
  position: relative;
}

.page-item-selected {
  font-weight: bold;
  background-color: #9073ac;
}

.menu-search-clickable-expand:focused {
  background-color: white;
}

.menu-search-input::placeholder {
  color: darkgray;
}

.menu-search-input:focus {
  outline: none;
}

.list-search-button > .menu-search-input::placeholder {
  color: lightgray;
}

.menu-search-button-expand {
  background-color: rgba(255, 255, 255, 1);
  width: calc(100% - 120px);
}

.menu-secondary-container {
  display: flex;
  min-height: calc(100vh - 60px);
}

.menu-find-no-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: calc(50% - 75px);
  color: lightgray;
}

@media (min-width: 800px) {
  .menu-find-no-data {
    right: 115px;
    left: 60%;
    width: 40%;
  }
}

.menu-find-input-icon {
  height: 40px;
  width: 200px;
  border: 2px solid lightgray;
  border-radius: 25px;
  position: relative;
}

.menu-find-input-icon-ellipsis {
  position: absolute;
  bottom: 0px;
  left: 15px;
  font-size: 25px;
}

.menu-search-cancel {
  position: absolute;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 8px;
}

.menu-secondary-left {
  background-color: #620606;
  width: 85px;
  padding: 30px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-secondary-left-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  cursor: pointer;
  transition: 0.2s ease background-color;
  border-radius: 15px;
  margin-bottom: 5px;
}

.menu-secondary-left-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  transition: 0.1s ease background-color;
}

.menu-secondary-right {
  background-color: #861414;
  flex: 1;
  padding-top: 25px;
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding-bottom: 5rem;
}
.menu-background-img{
 
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.foot-icon-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  flex: 1;
  height: 100%;
  padding: 0px 2px 5px 2px;
  white-space: nowrap;
  font-size: 15px;
  overflow: hidden;
}

@media (max-width: 350px) {
  .foot-icon-container {
    padding: 0px 5px 5px 5px;
  }
}

.foot-icon-container > span {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
  width: 100%;
}

.foot-icon-container:hover {
  color: white;
  text-decoration: none;
}

.left-item-selected {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 15px;
}

.gradient-background {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ) !important;
  padding-top: 25px !important;
}

.selected-nav {
  transition: 0.3s;
  border-radius: 15px;
}

.more-icon-container:active {
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.1s;
}

.App {
  width: 100%;
  height: 100%;
}

.fade-enter {
  position: absolute;
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.scroll-support {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #933939;
  z-index: -1;
}

.cardGridContainer {
  background-color: white;
}

div.transition-group {
  position: relative;
}

.rhap_progress-bar {
  background-color: #000;
  border-radius: 5px;
  height: 5px;
}

.rhap_progress-indicator {
  position: relative;
}

.content-audio-container {
  min-height: calc(100vh - 54px);
  flex-direction: column;
}

.body-content-audio {
  background-color: indianred;
}

section.route-section {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.navbar {
  z-index: 20;
  justify-content: start !important;
}

.videolist-container {
  height: 100%;
}

.videolist-text {
  padding: 10px;
  text-align: left;
}

.videolist-img {
  width: 100%;
  height: 250px;
  background-size: 100% 100%;
}

.video-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: rgb(73, 27, 92);
  color: white;
}

.container-default {
  padding: 15px 10px;
  background-color: white;
}

.baby-title {
  color: rgb(75, 64, 87);
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  line-height: 110%;
  margin-bottom: 10px;
}

@media (min-width: 1000px) {
  /* .container-default{
   padding: 15px 20%;
  } */

  .video-wrapper {
    margin-bottom: 10px;
  }
}

.video-wrapper {
  min-height: 230px;
  display: flex;
  align-items: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.baby-subtitle {
  color: rgb(75, 64, 87);
  font-size: 16px;
  text-align: left;
  font-weight: 500;
}

.baby-text {
  color: rgb(75, 64, 87);
  font-size: 14px;
  text-align: left;
}

.alquimia-img {
  height: 50vh;
  width: 100%;
  object-fit: cover;
}

.pato-container {
  background-color: rgb(53, 27, 66);
  height: 100%;
  padding: 10px;
  min-height: calc(100vh - 54px - 000px);
}

.pato-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}

.maratonando-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.maratonando-card {
  background-color: pink;
  width: calc(50% - 4px);
  height: 200px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maratonando-card.big {
  background-color: pink;
  width: calc(100% - 2px);
}

.new-pato-wrapper {
  background-color: pink;
  width: 100%;
  display: flex;
  position: relative;
}
.new-pato-wrapper-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  padding: 5px;
}
.new-pato-wrapper-inside {
  display: block;
  padding-bottom: 140%;
}

.zoom-img {
  width: 100%;
}

.zoom-text {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  display: flex;
  align-items: center;
}

.new-pato-img {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0px;
  bottom: 20px;
  margin: auto;
}

.new-pato-tile {
  height: 100%;
  width: 100%;
  bottom: 0px;
  object-fit: cover;
}

.new-pato-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 0px 5px;
  line-height: normal;
}

.login-landpage-container {
  background-color: #f7f5f5;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.login-landpage-header {
  background-color: white;
  width: 100%;
  height: 100px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  color: #60686f;
  font-size: 14px;
  text-decoration: none;
}

.login-landpage-header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-landpage-header-right > div:not(:first-child) {
  margin-left: 30px;
}

.login-landpage-img {
  height: 50px;
}

.login-landpage-header-right > div > .btn.botao-login {
  transition: 0.2s ease-out;
}

.login-landpage-header-right > div > .btn.botao-login:hover {
  transform: scale(1.05);
  transition: 0.2s ease-out;
}

@media (max-width: 700px) {
  .pato-card {
    height: 400px;
    font-size: 18px;
  }
  .content-site-rating {
    height: auto;
  }
  .login-landpage-img {
    height: 30px;
  }
  .login-landpage-header-right > div:not(:first-child) {
    margin-left: 15px;
  }
}

.not-found-container {
  background-color: rgb(117, 71, 163);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: calc(100% - 000px - 54px);
}
.not-found-wrapper {
  height: 90vh;
  width: 100%;
}

.tile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-player-thumb {
  height: 75px !important;
  width: 75px !important;
  position: absolute;
  top: calc(50% - 38px);
  left: calc(50% - 38px);
}

.container-mosaic {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 54px);
}

.container-mosaic-journey {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 54px - 51px);
}

.tile {
  min-height: 205px;
  flex: 1;
  min-width: 50%;
  box-sizing: border-box;
  position: relative;
}

.with-video > .tile {
  min-height: 205px;
  height: auto;
}

.tile:nth-child(1) {
  min-width: 100%;
}

.tile:nth-child(1) {
  grid-column: span 6;
  grid-row: span 2;
}

.tile:nth-child(n + 2) {
  grid-column: span 3;
}

.fr-draggable > video {
  width: 100% !important;
}

.fr-draggable {
  display: flex;
  justify-content: center;
}

@media (min-width: 600px) {
  .with-video > .tile {
    min-height: 350px;
    height: auto;
  }
}

.activate-account-card {
  height: auto !important;
}

.activate-account-warning-card {
  height: auto !important;
  text-align: center;
}

.activate-account-body > form > div > small {
  white-space: normal;
  text-align: center;
}

@media (min-width: 900px) {
  .fr-draggable > video {
    width: 55% !important;
  }
  .tile {
    /*height: 350px;*/
    min-width: 33.33%;
  }

  .with-video > .tile {
    min-height: 350px;
    height: auto;
    min-width: 33.33%;
  }

  .tile:nth-child(1) {
    min-width: 100%;
  }
}

.botao-login {
  color: #fff !important;
  align-items: center;
}

@keyframes fadeInApp {
  0% {
    opacity: 0;
    margin-top: 20px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.login-wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.login-img-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
}

.login-wrapper {
  padding: 40px;
  animation: fadeInApp 0.6s ease;
  border: 1px solid rgba(108, 119, 183, 0.52);
  background-color: white;
  border-radius: 5px;
  width: 70%;
}

.login-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.login-image-wrapper {
  overflow: hidden;
  background-color: white;
  flex: 1;
}

.login-form-wrapper {
  overflow: hidden;
  flex: 1;
}

.login-error-text {
  color: rgb(175, 0, 0);
  font-weight: 500;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1px 10px 3px 0px;
  border-radius: 100px;
}

.login-forgot {
  color: #804e6c;
  font-weight: 400;
  font-size: 14px;
  padding: 1px 0px 5px;
}

.login-forgot:hover {
  color: rgb(107, 65, 90);
  font-weight: 500;
}

.login-blur {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 30px;
  border-radius: 10px;
  backdrop-filter: blur(6px);
}

.forgot-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.logout-button {
  color: white;
  right: 15px;
  position: absolute;
  cursor: pointer;
}

.header-title {
  color: white;
  margin: 0 auto;
  padding-right: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 80%;
}

.post-list-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  flex-wrap: wrap;
  min-height: calc(100vh - 54px - 000px);
  padding: 10px;
}

/* @media(min-width: 800px){
  .post-list-container{
    padding: 0px 25%;
  }
} */

.post-list-img {
  position: absolute;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  top: 0;
  height: 100%;
  width: 100%;
}

.post-list-img-container.first {
  margin-top: 30px;
}

.post-list-img-container {
  height: 0;
  position: relative;
  height: 40vh;
}

.post-list-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeInMenu ease 0.3s;
  position: relative;
  padding: 1%;
  
}

.post-list-item-others {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  border-top: rgb(176, 163, 191) 1px solid;
  padding: 20px 0;
}

.card-list-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  padding: 15px;
}

.post-list-header {
  height: 250px;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.post-list-header-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.post-list-header-image-container{
  margin-top: 2%;
  margin-right: 2%;
}
@media (min-width: 800px) {
  .post-list-header {
    height: 50vw;
    background-size: cover;
    background-position: center;
  }

  .post-list-header-image-container {
    height: 50vh;
    background-size: cover;
    background-position: center;
    margin-top: 2%;
    margin-right: 2%;
  }

  .infinite-scroll-component.grid .post-list-header-image-container {
    height: 280px;
  }
}

/* Page Grid */
.infinite-scroll-component.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 10px;
}

@media (max-width: 800px) {
  .infinite-scroll-component.grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px 0;
    padding: 0;
  }
}
@media(max-width: 600px){
  .post-list-header-image-container{
    margin-top: 3%;
    margin-right: 3%;
  }
}
.infinite-scroll-component.grid .post-list-item {
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 2px #aaa;
  border-radius: 2px;
}

.infinite-scroll-component.grid .post-list-footer {
  padding: 12px 16px;
}

.single-page-container {
  margin-bottom: 0px;
  height: 100%;
  min-height: calc(100vh - 54px - 000px);
}

@media (orientation: landscape) {
  .post-list-header .card-list-card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 700px) {
  .post-list-header .card-list-card {
    height: 500px;
  }
}
@media (min-width: 1000px) {
  .post-list-container {
    padding: 0px 10%;
  }
}

@media (min-width: 1300px) {
  .post-list-container {
    padding: 0px 15%;
  }
  .post-list-item-others {
    height: 200px;
    padding-left: 10px;
  }
}

.loader-center {
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 33vh;
}

.loader-infinite {
  margin: 30px auto;
  position: relative;
}

.content-post-container {
  width: 100%;
  min-height: calc(100vh - 54px - 000px);
  height: calc(100vh - 54px - 000px);
  background-color: white;
  position: fixed;
  color: black;
  padding: 5px 10px;
  text-align: left;
  overflow-y: scroll;
}

.no-content {
  position: absolute;
  left: 0;
  right: 0;
  width: 300px;
  top: 0;
  bottom: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: white;
}

.modal-player-rating,
.modal-player-rating-mobile {
  bottom: 5px;
  left: 0;
  width: 100%;
}

.modal-player-rating,
.modal-player-rating-web {
  display: flex;
  justify-content: center;
}

@media (min-width: 800px) {
  .content-post-container {
    padding: 20px 15%;
  }
}

@media (min-width: 1200px) {
  .content-post-container {
    padding: 20px 15%;
  }
}

.image-loader {
  position: absolute;
}

.pdf-list-header-margin {
  margin-top: 75px;
}

.pdf-list-wrapper {
  width: 255px;
  display: flex;
  flex-direction: column;
  animation: fadeInMenu ease 0.3s;

  background-color: #fff;
  margin: 15px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #3b3a3a18;
  padding: 13px 20px;

  align-items: stretch;
  flex-wrap: wrap;
  align-content: stretch;
  position: relative;
}

.pdf-list-img-wrapper {
  height: 171px;
  width: 214px;
  position: relative;
}

.pdf-list-text {
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.pdf-viewer-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #58545c !important;
}

.pdf-viewer-page {
  display: flex;
  padding: 10px;
  background-color: white;
  position: fixed;
  bottom: 100px;
  left: calc(50% - 65px);
  box-shadow: 0px 0px 10px gray;
  border-radius: 5px;
}

.pdf-viewer-page-canvas > canvas {
  width: auto !important;
  height: calc(100vh - 134px) !important;
}

.pdf-viewer-page-arrow {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.pdf-viewer-page-arrow:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease;
}

@media (max-width: 800px) {
  .pdf-viewer-page-canvas > canvas {
    width: 100% !important;
    height: auto !important;
  }

  .pdf-list-header-margin {
    margin-top: 50px;
  }
}

.content-post-image {
  background-size: contain;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.post-list-text {
  width: 100%;
  font-weight: 700;
}
.post-list-text:nth-child(1) {
  margin-bottom: 10px;
}
.post-list-knowmore {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.post-list-footer {
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px 0;
  justify-content: center;
  text-align: left;
  line-height: normal;
}

.mosaic-icon {
  min-height: 205px;
  flex: 1;
  min-width: 50%;
  border: 3px solid transparent;
  box-sizing: border-box;
  position: relative;
}

.mosaic-icon img {
  width: 100%;
  height: 60px;
  object-fit: contain;
  top: calc(25% + 20px);
  position: absolute;
}

.post-list-footer-others {
  height: 100%;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: left;
  line-height: normal;
  margin-left: 10px;
}

@media (orientation: landscape) {
  .post-list-item-others {
    width: 50%;
  }
  .post-list-item-others:nth-child(even) {
    border-right: rgb(176, 163, 191) 1px solid;
  }
}

.post-list-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.live-menu-container {
  display: flex;
  flex-direction: column;
}

.live-menu-item {
  width: 100%;
  height: 48vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100% - 54px - 000px);
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  top: -1px;
}

.edit-login-input {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.button-disabled {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.edit-button-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.profile-card {
  margin: 4%;
  display: flex;
  padding: 12px;
  margin-top: 10%;
  margin-bottom: 10px;
  border: 1px solid white;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #d8dce4;
  border-radius: 5px;
}
.title-profile-edit {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  color: #666;
  margin: 4%;
}
.subtitle-profile-edit {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19, 07px;
  color: #666;
  margin: 4%;
}
.hr-profile {
  border: 1px dashed #dcdcdc;
  margin-bottom: 10px;
  margin-top: 10px;
}
.show-more-profile {
  height: 50px;
  background-color: #edf2f2;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -3px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.profile-photo-perfil {
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid white;
  background-color: lightslategray;
  width: 60px;
  margin-top: -4%;
  height: 60px;
}
.btn-edit-perfil {
  border-radius: 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0.39rem 1.2rem !important;
}
.td-points-profile {
  font-weight: 700;
  color: rgb(252, 151, 0);
  display: flex;
  margin: 0;
  border-left: 1px solid #dcdcdc;
  padding: 6px;
  align-items: center;
  width: 75px;
  justify-content: center;
}
.td-icon-profile {
  display: flex;
  align-items: center;
  padding: 6px;
}
.tr-profile {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dcdcdc;
  height: 50px;
}
.table-profile {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  padding: 0px;
  border-radius: 4px;
}
.profile-edit-item {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.profile-edit-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(100% - 54px);
  width: 100%;
  overflow: scroll;
}

.profile-top-bar {
  flex: 2 1 0%;
  background-color: indianred;
  position: relative;
  padding: 0 20%;
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 70px);
  color: white;
}

.profile-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: calc(100% - 70px);
  color: #666666;
}
.profile-edit-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.profile-bottom-bar {
  flex: 1;
  background-color: white;
}

@media (orientation: landscape) {
  .profile-bottom-bar {
    flex: 0;
  }
}

.profile-local {
  position: absolute;
  bottom: 0px;
  height: 70px;
  width: 100%;
  background-color: #861414;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0px 15px;
}

.profile-gear {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  background-color: #861414;
  border-radius: 100%;
  padding: 6px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.saiba-mais {
  margin-top: 15px;
  color: white;
  background-color: #800080;
  padding: 2px 5px 4px;
  font-size: 14px;
  font-weight: 500;
}

.post-list-no-data {
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding-top: calc(50vh - 000px - 54px);
  position: fixed;
  left: 0;
  right: 0;
}

.fade.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-principal {
  background-color: white;
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-principal-warning {
  margin-top: 15px;
  background-color: transparent;
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
  min-height: 85px;
  align-items: center;
  display: flex;
}

.modal-footer-warning.modal-footer > button {
  font-size: 14px;
}

.modal-header-warning {
  justify-content: center !important;
  align-items: center !important;
  font-size: 18px !important;
  padding: 25px !important;
  width: 100%;
}

.modal-warning-close-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 10px;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.login-landpage-contact-button {
  cursor: pointer;
}

.login-landpage-contact-button:hover {
  color: black;
}

.modal-warning-close-container:hover {
  transition: 0.2s ease-out;
  transform: scale(1.1);
}

.modal-warning-close {
  height: 20px;
  width: 20px;
  opacity: 0.8;
}

.form-check-input {
  margin-top: 0.4rem !important;
}

.modal-principal > div > .form-group {
  margin-bottom: 0px;
}

.modal-principal-warning > div > .form-group {
  margin-bottom: 0px;
}

.modal-support-blur {
  background-color: rgba(255, 255, 255, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: -10;
  backdrop-filter: blur(10px);
  border-radius: 4px;
}

.modal-main-container > div > .modal-content {
  background-color: transparent;
}

.modal-main-container > div > .modal-content > .modal-header {
  border-bottom: 0px solid darkgray;
}

.modal-main-container > div > .modal-content > .modal-footer {
  border-top: 0px solid darkgray;
}

.modal-support-blur-warning {
  background-color: white;
}

.modal-principal {
  background-color: transparent;
}

.form-check-label {
  line-height: normal;
}

.modal-body-term-container {
  max-height: 300px;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.modal-body-warning {
  /* TODO - vazio */
}

.modal-footer-warning {
  width: 100%;
}

.term-error {
  color: orangered;
  font-weight: 400;
}

@media (max-width: 400px) {
  .form-check-label {
    font-size: 14px;
  }

  .term-error {
    font-size: 14px;
  }
}

.noContent {
  margin: 0 auto !important;
}

.messages-container {
  padding: 15px;
}

.messages-box-container {
  padding: 10px 15px;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.message-full-container {
  padding: 15px;
  height: calc(100vh - 54px - 000px);
}

.message-full-box {
  padding: 10px 15px;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  height: 100%;
}

.message-full-text {
  margin-top: 5px;
  font-family: "PT Sans Narrow";
  font-size: 16px;
  color: #1d2129;
  overflow: hidden;
  line-height: 25px;
}

.message-full-title {
  font-family: "PT Sans Narrow";
  font-weight: 700;
  font-size: 22px;
  color: #1d2129;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media (min-width: 1000px) {
  .messages-container {
    padding: 0px 25vw;
    margin-top: 15px;
  }
}

.messages-box-title {
  font-family: "PT Sans Narrow";
  font-weight: 700;
  font-size: 18px;
  color: #1d2129;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 70%;
}

.messages-box-text {
  font-family: "PT Sans Narrow";
  font-size: 16px;
  color: #1d2129;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  line-height: 20px;
  margin-bottom: 3px;
}

.box-read-bubble {
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: #861414;
  right: 5px;
  top: 5px;
  border-radius: 100%;
}

.box-read-time {
  position: absolute;
  right: 15px;
  top: 12px;
  color: grey;
  font-size: 14px;
}

.box-full-read-time {
  position: absolute;
  right: 15px;
  bottom: 12px;
  color: grey;
  font-size: 14px;
}

.search-button {
  right: 15px;
  position: absolute;
  cursor: pointer;
  transition: width 0.3s ease, background-color 0.5s ease;
  padding: 5px;
  width: 35px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
}

.search-clickable-expand {
  width: 35px;
  height: 30px;
  position: absolute;
  right: 0px;
}

.search-input {
  position: absolute;
  background-color: transparent;
  border: 0;
  width: calc(100% - 54px);
  color: white;
}

.search-clickable-expand:focused {
  background-color: white;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.search-input:focus {
  outline: none;
}

.search-button-expand {
  background-color: rgba(255, 255, 255, 0.3);
  width: calc(100% - 55px - 80px);
}
.points {
  border-radius: 20px;
  border: 1px solid #dbdbdb;
  padding-left: 25px;
  padding-right: 25px;
  height: 30px;
}
.points span {
  color: #fc9700;
  font-size: 14px;
}
.points .number {
  font-weight: 700;
}
.section-profile {
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 4%;
}
.section-form {
  flex-direction: row;
  flex-wrap: wrap;
}
.section-form-edit {
  width: 45%;
  margin-right: 10px;
}
.form-edit-button {
  justify-content: flex-start;
  align-items: flex-start;
}
.form-edit-button-flex {
  flex: 0.5;
}
.heigth-rate-in-content-site{
  height: auto !important;
}
@media (min-width: 800px) {
  .search-button-expand {
    width: calc(40% - 32px - 80px);
  }
}
.size-porcentagem-header {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.porcentagem {
  top: auto;
  position: absolute;
  right: 46%;
}
.concluided-pageList-icon{
  display: flex;
  flex-direction: row;
  justify-content: end;
  line-height: 14px;
  color: #1DB954;
  width: 100%;
  position: absolute;
  z-index: 1;
  right: 10%;
}
.concluided-pageList-tile{
  display: flex;
  flex-direction: row;
  justify-content: end;
  line-height: 14px;
  color: #1DB954;
  width: 100%;
  position: absolute;
  z-index: 1;
  right: 1%;
}
@media (max-width: 768px) {
  .pdf-list-wrapper {
    width: 100%;
    flex-direction: row;
    padding: 10px 20px;
    margin: 7.5px 0px;
    align-items: center;
    justify-content: center;
  }

  .pdf-list-img-wrapper {
    height: 80px;
    width: 80px;
    padding-top: 0px;
    position: relative;
  }

  .pdf-list-text-wrapper {
      display: inline-block !important;
      width: calc(100% - 80px);
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      text-align: left;
      line-height: normal;
      padding-left: 10px;
  }

  .pdf-list-text {
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .profile-container {
    padding-left: 0%;
    padding-right: 0%;
    top: auto;
    padding-top: 10%;
  }
  .porcentagem{
    right: 10%;
  
  }
  .size-porcentagem-header{
  
    width: 80%;
    margin-bottom: 1px;
  }
  .profile-photo-perfil {
    margin-top: -10%;
  }
  .section-form {
    flex-direction: column;
  }
  .section-form-edit {
    width: 100%;
  }
  .profile-card {
    margin-top: 5%;
  }
  .form-edit-button-flex {
    flex: 1;
  }
  .activate-account-card {
    width: 95vw;
  }
  
  .card-body {
    max-width: 65%;
    padding: 10px !important;
  }
  .activate-account-card  > .card-body {
    max-width: none !important;
    width: 100%;
  }
  .activate-account-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Component: MosaicJourneyList */
.mosaic-text-journey {
  position: absolute;
  display: block;
  bottom: 5px;
  margin: 1rem;
  color: white;
  text-align: left;
  font-size: 20px;
}

@media (max-width: 500px) {
  .mosaic-text-journey {
    font-size: 16px;
  }
}
.maxRatingWhite {
  font-size: 14px;
  font-weight: normal;
  color: white;
}
.progress-bar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border: 2px red solid;
  border-image: linear-gradient(to right, red 60%, grey 40%) 1;
}

/* Component: MosaicJourneyStepList */
.journey-tile {
  min-height: 205px;
  flex: 1;
  min-width: 50%;
  border: 0px solid transparent;
  box-sizing: border-box;
  position: relative;
}
.journey-tile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.with-video > .journey-tile {
  min-height: 205px;
  height: auto;
}
.journey-tile:nth-child(1) {
  min-width: 100%;
}
.journey-tile:nth-child(1) {
  grid-column: span 6;
  grid-row: span 2;
}
.journey-tile:nth-child(n + 2) {
  grid-column: span 3;
}
@media (min-width: 600px) {
  .with-video > .journey-tile {
    min-height: 350px;
    height: auto;
  }
}
@media (min-width: 900px) {
  .fr-draggable > video {
    width: 55% !important;
  }
  .journey-tile {
    height: 350px;
    min-width: 33.33%;
  }
  .with-video > .journey-tile {
    min-height: 350px;
    height: auto;
    min-width: 33.33%;
  }
  .journey-tile:nth-child(1) {
    min-width: 100%;
  }

}
@media (max-width: 900px) {
  .modal-principal-warning {
    align-self: center;
  }
  .journey-tile {
    flex: none;
    width: 100%;
  }
  .login-image-wrapper {
    display: none;
  }
  .login-wrapper {
    width: 85%;
    padding: 20px;
    backdrop-filter: blur(6px);
    background-color: hsla(0, 0%, 100%, 0.55);
  }
  .profile-top-bar {
    padding: 0 20px;
  }
}

/* Component: JourneyHeader */
.journey-header-header {
  display: flex;
  margin-top: 3px;
  align-items: center;
}
.back-to-journeys-button {
  padding: 5px;
  margin: 5px 10px;
  border: 0.5px solid lightgray;
  background-color: unset;
  position: relative;
  border-radius: 100px;
  box-shadow: 0px 0px 7px gray;
  transition: box-shadow 0.2s ease-out;
}

.back-to-journeys-button:hover {
  transition: box-shadow 0.2s ease-out;
  box-shadow: 0px 0px 10px black;
}

.journey-title-label {
  margin: 10px 0;
  color: rgb(100, 100, 100);
}

/* Component: JourneyRatingCard */
.journey-rating-card {
  margin: 0 20px 20px 20px;
  padding: 0.5rem 0 20px;
  font-size: 16px;
  color: rgb(100, 100, 100);
  display: flex;
  border-radius: 5px;
  background-color: white;
  box-shadow: 2px 2px 2px rgb(200, 200, 200);
}
.journey-progress-text {
  margin: 1rem 0;
  padding: 0 1rem;
  width: 60%;
  font-weight: bold;
  font-size: 13px;
}
.journey-progress-bar {
  color: rgb(122, 122, 122);
  background-color: rgb(122, 122, 122);
  margin-top: 5px;
}
.journey-progress {
  width: 50%; /* This will have to be set programatically later */
  border: solid 2px red;
}
.journey-rating-main {
  padding: 6px 1rem;
  width: 40%;
  text-align: right;
}
@media (max-width: 748px) {
  .journey-rating-bottom {
    display: none;
  }
  .journey-rating-main {
    margin-top: 17px;
  }
  
  .back-to-journeys-button {
    padding: 10px;
    margin: 5px 10px;
    border: 0.5px solid lightgray;
    background-color: unset;
    position: relative;
    border-radius: 100px;
    box-shadow: 0px 0px 7px gray;
  }
}
.journey-rating-link {
  font-size: 12px;
  color: rgb(100, 100, 100);
}
.star-icon {
  margin-top: -3px;
  margin-right: 6px;
}
.journey-rating {
  font-size: 13px;
  font-weight: bold;
  color: gold;
  margin-right: 2px;
}
.journey-max-rating {
  font-size: 14px;
  font-weight: normal;
  color: rgb(100, 100, 100);
}

/* Component: JourneyStepNavigation */
.step-main-container {
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.step-progress-bar {
  width: 100%;
  color: rgb(122, 122, 122);
  background-color: rgb(122, 122, 122);
}
.step-progress {
  border: solid 2px red;
}
.step-footer {
  background-color: rgb(50, 50, 50);
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.step-footer-button {
  margin: 10px 35px;
  padding: 10px;
  background-color: rgb(60, 60, 60);
  color: white;
  border: unset;
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
}

/*Component: JourneyStepSideMenu*/
.step-menu-expand{
  width: 100%;
  top: 0;
  position: fixed;
  background-color: rgb(65, 65, 65);
  height: calc(100%);
  transition: 0.3s;
  overflow-y: hidden;
  overflow-x: hidden;
  right: 100%;
  z-index: 1040;
}
.step-menu-expand-open{
  right: 75%;
  z-index: 1040;
  width: 25%;
  color: white;
}
.card-footer-login-options{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 800px){
  .step-menu-expand-open{
    right: 0;
    width: 100%;
  }
}
.step-side-menu-header{
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid gray;
  background-color: rgb(50, 50, 50);
}
.step-side-menu-header-button{
  color: white;
  border: unset;
  background: unset;
}
.step-side-menu-header-text{
  text-align: center;
  align-self: center;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
}
.step-side-menu-title{
  border-bottom: 1px solid gray;
  padding: 1.5rem 1rem;
}
.step-side-menu-title-top{
  font-size: 10px;
}
.step-side-menu-title-bottom{
  font-size: 11px;
  font-weight: bold;
}
.step-side-menu-body{
  border-bottom: 1px solid gray;
  padding: .5rem;
}
.step-side-menu-button{
  border: none;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.step-side-menu-body-items {
  display: flex;
}
.step-side-menu-body-icon{
  align-self: center;
  padding-bottom: 5px;
}
.step-side-menu-body-steps{
  align-self: center;
  font-size: 10px;
  padding: 10px;
}
.step-side-menu-selected{
  color: teal;
  font-weight: bold;
}
.step-side-menu-footer{
  display: flex;
  padding: 1rem;
  bottom: 0;
  top: 93%;
  position: absolute;
  background-color: rgb(50, 50, 50);
  width: 100%;
}
.step-side-menu-footer-text {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  align-self: center;
  padding-left: 1rem;
}

/*Component: ImpactStepSideMenu*/
.impact-step-menu-expand {
  width: 100%;
  top: 0;
  position: fixed;
  background: #2162ab;
  height: calc(100%);
  transition: 0.3s;
  overflow-y: hidden;
  overflow-x: hidden;
  right: 100%;
  z-index: 1040;
}
.impact-step-side-menu-header-text {
  text-align: left;
  align-self: center;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  font-family: "Open Sans";
  margin-left: 15px;
  color: white;
}
.impact-step-menu-expand-open {
  right: 40%;
  z-index: 1040;
  width: 60%;
  color: white;
}
@media (max-width: 800px) {
  .impact-step-menu-expand-open {
    right: 0;
    width: 100%;
  }
}
.impact-step-side-menu-title-top {
  font-size: 10px;
  font-family: "Open Sans";
}
.impact-step-side-menu-title-bottom {
  font-size: 12px;
  font-weight: bold;
  font-family: "Open Sans";
}
.impact-step-side-menu-footer {
  display: flex;
  padding: 1rem;
  bottom: 0;
  top: 93%;
  position: absolute;
  background: #282828;
  width: 100%;
}
.impact-step-side-menu-footer-text {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  align-self: center;
  padding-left: 1rem;
  font-family: "Open Sans";  
  color: white;
}
.impact-step-side-menu-title {
  border-bottom: 1px solid #4d81bc;
  padding: 1.5rem 1rem;
}
.impact-step-side-menu-header {
  display: flex;
  padding: 1rem;
  background: #282828;
}
.impact-content-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15%;
}
.impact-content-body > div {
  flex: 40% 1;
  margin: 10px;
  border-radius: 10px;
}
.impact-content-card {
  display: flex;
  padding: 15px;
  border-radius: 10px;
}
.impact-content-card:hover {
  background: #1a4e88;
}
.impact-content-icon {
  display: flex;
  align-items: center;
  padding: 10px;
}
@media (max-width: 800px) {
  .impact-content-icon {
    display: none;
  }
}
.impact-content-text {
  padding: 5px;
  align-self: center;
}
.impact-content-title {
  display: flex;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.impact-read-text {
  color: #badaff;
}
.impact-content-description {
  display: flex;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

/*Component: ImpactFullMenu*/
.impact-full-menu-expand {
  width: 100%;
  top: 0;
  position: fixed;
  height: calc(100%);
  transition: 0.3s;
  overflow-y: hidden;
  right: 100%;
  z-index: 1040;
  background-color: rgb(108, 119, 183);
}
.impact-full-menu-expand-open {
  right: 0;
  z-index: 1040;
  width: 100%;
  overflow: auto;
}
@media (max-width: 800px) {
  .impact-full-menu-expand-open {
    right: 0;
    width: 100%;
  }
}
.impact-secondary-header-text {
  padding-left: 2%;
  font-weight: normal;
}
.impact-full-menu-body {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 128px);
}
.impact-full-menu-body-item{
  flex: 1;
  display: flex;
}
.impact-full-menu-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px;
}
.impact-left-content {
  width: 215px;
  display: flex;
}
.impact-left-content-icon {
  font-weight: bold;
  font-size: 40px;
  width: 40%;
  text-align: center;
  align-self: center;
}
.impact-left-content-text {
  font-size: 14px;
  font-weight: bold;
  font-family: "Open Sans";
  width: 60%;
  text-align: left;
  align-self: center;
}
.impact-selected-content {
  background-color: #13549d;
}
.impact-right-content {
  width: 1000px;
  flex: 1;
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-right: 30px;
}
.impact-chapter-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: auto;
}
.impact-chapter-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  background-color: rgba(255,255,255,0.1);
}
.impact-chapter-list::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}
.impact-full-menu-expand::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,0.5);
}
.impact-full-menu-expand::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.impact-full-menu-expand::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}
.impact-chapter-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,0.4);
}
.impact-page-icon {
  position: relative;
  display: flex;
  margin-bottom: 3px;
}
.impact-page-completed-icon {
  background-color: rgb(53, 170, 53);
  height: 20px;
  width: 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  align-self: end;
}
.impact-chapter-text {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: bold;
  color: rgb(163, 172, 222);
}
.impact-page-list {
  display: flex;
  margin-top: 3px;
}
.impact-chapter-list {
  display: flex;
}
.impact-page {
  padding: 10px 10px 8px 10px;
  margin: 0px 10px 5px 0px;
  max-width: 205px;
  border-radius: 10px;
}
.impact-page-selected {
  background-color: rgba(255,255,255,0.1);
  font-weight: bold;
}
.impact-page:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}
.impact-page-text {
  font-size: 12px;
  font-family: "Open Sans";
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.impact-full-menu-footer{
  display: flex;
  padding: 1rem;
  background: #282828;
  width: 100%;
}  

.impact-page-image {
  max-height: 30px;
}

@media (max-width: 800px) {
  .impact-full-menu-body {
    overflow: hidden;
  }
  .impact-left-content {
    width: 100%;
  }
  .impact-full-menu-row {
    flex-direction: column;
  }
  .impact-left-content-icon {
    width: auto;
  }
  .impact-page-list {
    flex-direction: column;
  }
  .impact-left-content {
    padding: 25px 30px;
  }
  .impact-left-content-icon {
    margin-right: 15px;
  }
  .impact-page {
    width: fit-content;
    padding: 10px;
    margin-bottom: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .impact-page-icon {
    margin-bottom: 5px;
  }
}